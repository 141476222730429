<template>
  <div style="background-color: white;padding: 0 20px;min-height: 600px;">
    <div style="height: 40px;padding-top: 20px">
      <img style="display: inline-block;height: 24px;vertical-align: middle" src="../../assets/img/robot/phone_back.png" @click="goBack()">
      <span v-if="num == 1" style="font-size: 17px;font-weight: 500;vertical-align:middle;color: #000613;margin-left: 10px">{{ $t('robot.billing_list_of_week') }}</span>
      <span v-if="num == 2" style="font-size: 17px;font-weight: 500;vertical-align:middle;color: #000613;margin-left: 10px">{{ $t('robot.revenue_list_of_week') }}(USDT)</span>
<!--      <el-select v-if="num == 1" style="width: 140px;position: relative;float: right;top: -8px;" v-model="userProfitNum" name="" @change="handleClick">-->
<!--        <el-option value="0" label="全部"></el-option>-->
<!--        <el-option value="3" label="黃金三角"></el-option>-->
<!--        <el-option value="2" label="BB">BB</el-option>-->
<!--        <el-option value="1" label="UB">UB</el-option>-->
<!--        <el-option value="4" label="網格">網格</el-option>-->
<!--        <el-option value="5" label="馬丁">馬丁</el-option>-->
<!--      </el-select>-->

<!--      <el-tabs class="rank_tab" v-if="num == 1" style="float: right;position: relative; width: 160px; top: 36px;" v-model="userProfitNum" @tab-click="handleClick">-->
<!--        <el-tab-pane :label="$t('robot.all')" name="0"></el-tab-pane>-->
<!--        <el-tab-pane style="padding-right: 10px" :label="$t('robot.sj')" name="3"></el-tab-pane>-->
<!--        <el-tab-pane :label="$t('robot.supreme_hedge')" name="6"></el-tab-pane>-->
<!--        <el-tab-pane style="padding: 0px 10px; width: 10px" :label="$t('robot.bb')" name="2"></el-tab-pane>-->
<!--        <el-tab-pane :label="$t('robot.ub')" name="1"></el-tab-pane>-->
<!--        <el-tab-pane :label="$t('robot.grid')" name="4"></el-tab-pane>-->
<!--        <el-tab-pane :label="$t('robot.martin')" name="5"></el-tab-pane>-->
<!--      </el-tabs>-->
    </div>

    <span style="display: block;color: #72777E;font-size: 16px;margin-top: 20px">{{ $t('robot.my_ranking') }}：<span v-if="myRankNum1 == $t('robot.not_listed') || myRankNum1 > 3">{{ myRankNum1 }}</span><span v-else style="color: #E3B936;">NO.{{myRankNum1}}<img style="margin-left: 4px" src="../../assets/img/crown.svg" alt=""></span></span>

    <el-table
        v-if="userProfitByWeekTop10List.length != 0"
        :data="userProfitByWeekTop10List"
        :show-header="title_status"
        style="width: 100%; margin-top: 10px">
      <el-table-column
          align="left"
          type="index"
          width="32px"
          prop="$index+1">
        <template slot-scope="scope">
          <img v-if="scope.row.rankNum==1" style="width: 30px;height: 30px;vertical-align: middle;" :src="require('../../assets/img/robot/no1.png')">
          <img v-if="scope.row.rankNum==2" style="width: 30px;height: 30px;vertical-align: middle;" :src="require('../../assets/img/robot/no2.png')">
          <img v-if="scope.row.rankNum==3" style="width: 30px;height: 30px;vertical-align: middle;" :src="require('../../assets/img/robot/no3.png')">
          <span v-if="scope.row.rankNum > 3" style="color: #000613; font-size: 14px;width: 30px;text-align: center;display: inline-block;">{{ scope.row.rankNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="left"
          width="165px"
          prop="1832222222">
        <template slot-scope="scope">
          <div style="display: block;">
            <img style="vertical-align:middle;width: 30px;height: 30px;border: 1px white solid;border-radius: 50%" :src="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')" @click="showImage(scope.row.user_face)">
<!--            <pop_image style="vertical-align:middle;" :img-url="scope.row.user_face?scope.row.user_face:require('../../assets/img/avatar.png')" :placement="'right'"/>-->
            <div style="display: inline-block;vertical-align: middle">
              <span style="display: block;vertical-align: middle;margin-right: 3px">{{ scope.row.mobile }}</span>
              <span v-if="scope.row.name" style="display: block;font-size: 12px;vertical-align: middle;margin-right: 5px;width: 90px;white-space: nowrap;text-overflow: ellipsis; overflow: hidden;">{{ scope.row.name }}</span>
            </div>
            <el-tooltip v-if="scope.row.is_exclusive == 1" effect="dark" :content="'专属链接用户燃料限时98%折扣'" placement="top">
              <span style="display: inline-block; background: #FA3D58;border: 2px solid #FFFFFF;border-radius: 25px 25px 25px 0px; width: 20px; height: 14px; line-height: 14px; font-size: 12px;font-weight: 700; text-align: center; color: #FEFEFE;position: absolute; top: 9px; left: 113px;">专</span>
            </el-tooltip>
<!--            <img v-if="scope.row.vip_type == 1" style="display: inline-block;cursor: pointer;vertical-align: middle;height: 13px;" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" @click="$router.push('grant_member')" :src="require('../../assets/img/VIP.svg')">-->
<!--            <img v-if="scope.row.vip_type == 2" style="display: inline-block;cursor: pointer;vertical-align: middle;height: 13px;" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" @click="$router.push('grant_member')" :src="require('../../assets/img/SVIP.svg')">-->
<!--            <img v-if="scope.row.vip_type == 3" style="display: inline-block;cursor: pointer;vertical-align: middle;height: 15px;" :style="{verticalAlign: (scope.row.mobile && scope.row.name)?'top':'middle'}" @click="$router.push('grant_member')" :src="require('../../assets/img/ssvip.svg')">-->
          </div>
        </template>
      </el-table-column>
      <el-table-column
          v-if="num == 1"
          align="right"
          width="65px"
          prop="name">
        <template slot-scope="scope">
          <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']" style="">{{ scope.row.yield?(scope.row.yield*100): '0' | roundTwoFilter }}%</span>
<!--          <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']" style="">{{ scope.row.yield?(scope.row.yield*100).toFixed(5): '0' | roundTwoFilter}}%</span>-->
        </template>
      </el-table-column>
      <el-table-column
          v-if="num == 2"
          align="right"
          width="65px"
          prop="address">
        <template slot-scope="scope">
          <span :class="[scope.row.rankNum<=3?'rank_num_color_y':'rank_num_color_']" style="">{{ scope.row.trade_profit | roundTwoFilter }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          align="right"-->
<!--          width="70px"-->
<!--          prop="name">-->
<!--        <template slot-scope="scope">-->
<!--          <a v-if="scope.row.allow_view_position == null || scope.row.allow_view_position == 0" style="color: #AB54DB;font-size: 14px; width: auto; cursor: pointer" @click="checkEvery(scope.row.user_id, scope.row.allow_view_position)">{{ $t('robot.see_policies') }}</a>-->
<!--          <a v-else style="color: #72777E;font-size: 14px; width: auto; cursor: pointer" @click="checkEvery(scope.row.user_id, scope.row.allow_view_position)">{{ $t('robot.see_policies') }}</a>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-empty v-else style="margin-top: 80px;" :description="$t('robot.no_data')"></el-empty>
    <!--看他人倉位設置-->
    <el-dialog
        class="dialog-mb-ub"
        :title="cangweiName"
        :visible.sync="dialogCheckCangwei"
        width="90%"
        style="height: auto;overflow: scroll;margin-bottom: 20px">
        <span v-if="num == 1" style="font-size: 14px; display: block;margin-top: -8px; margin-bottom: 20px;">{{ $t('robot.cumulative_billing_volume_week') }}：{{ weekTradeCount }}{{ $t('robot.order') }}</span>
      <div>
        <!-- 黃金三角 -->
        <div v-if="dialogHuangjinList.length != 0" class="shouyi_div" style="display: inline-block; min-width: 281px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="height: 150px; background: #FFFAEF;position: relative">
              <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
                <span class="robot-add-name" style="color:#1E2329;">{{ $t('robot.golden_triangle_arbitrage') }}</span>
                <span class="robot-add-des" style="color: #72777E">{{ $t('robot.transaction_pair_added') }}：{{huangjinNum}}</span>
              </div>
              <div style="display: inline-block; width: 50%;text-align: center">
                <img style="width: 136px; height: 140px;" src="../../assets/img/robot/pic_ua_new.png">
              </div>
              <img class="sj-svip-id" style="position:absolute; top: 0px;right: 0px" src="../../assets/img/robot/svip_id.png">
            </div>
          </div>
          <div style="margin-top: 3px">
            <thead style="width: 100%;  display: inline-table; border-bottom: 1px #EAECEF solid;margin-bottom: -2px;">
            <tr style="width: 100%">
              <th class="tc th" style="">{{ $t('robot.init_space') }}</th>
              <th class="tc th">{{ $t('robot.side') }}</th>
              <th class="tc th">{{ $t('robot.trade_currency') }}</th>
            </tr>
            </thead>
            <div style="text-align: center; margin-top: 6px" v-if="dialogHuangjinList==null||dialogHuangjinList.length == 0">
              <span style="padding: 18px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
            </div>
            <table v-if="dialogHuangjinList.length!=0" class="table" style="width: 100%;border-bottom: 1px #EAECEF solid; display: inline-block;position: relative">
              <tbody style="width: 100%; display: inline-table;">
              <tr v-for="(item, i) in dialogHuangjinList" style="width: 100%;">
                <td class="tc" style="width: 100%; position: relative">
                  <table style="width: 100%">
                    <tr style="width: 100%" v-for="(item, i) in item.symbols">
                      <td class="tc td" style="color: #5c6be8; cursor: pointer">
                        <span style="display:block; color: #E3B936">{{ item.coin_one }}</span>
                        <span style="display:block; color: #1E2329">{{ item.space }}</span>
                      </td>
                      <td class="tc td" style="color: #52C41A;font-size: 17px; cursor: pointer">⇀</td>
                      <td class="tc td" style="cursor: pointer">{{ item.coin_two }}</td>
                    </tr>
                  </table>
                  <img v-if="item.robot_type == 'bn'" style="position: absolute; top:0px; right: -2px" src="../../assets/img/robot/binance_sign.svg">
                  <img v-if="item.robot_type == 'ok'" style="position: absolute; top:0px; right: -2px" src="../../assets/img/robot/okex_sign.svg">
                  <div class="" style="position: relative;margin: 10px 0px -15px 0px; border-top:1px #EAECEF solid;"></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="dialogBBList.length != 0" class="rank_div robot-earn-mb" style="margin: 20px -20px; border-top:1px #EAECEF solid"></div>

        <!-- BB交易 -->
        <div v-if="dialogBBList.length != 0" class="shouyi_div shouyi_div2"
             style="display: inline-block; vertical-align: top;min-width: 281px">
          <div style="height: 150px; background: #FFFAEF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.bb_trade') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}:{{ BBNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_bb_new.png">
            </div>
          </div>
          <el-table
              :data="dialogBBList"
              :header-cell-style="{color:'#72777E'}"
              :cell-style="{fontSize: '12px'}"
              style="width: 100%;display: inline-block;vertical-align: top;position: relative">
            <el-table-column
                align="center"
                prop="date"
                :label="$t('robot.init_space')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_one }}</span>
                <span
                    style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space }}</span>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="name"
                :label="$t('robot.side')">
              <template slot-scope="scope">
                <span style="font-size:17px;padding: 3px 0px 0px;line-height: 6px;color: #52C41A;display: block">⇀</span>
                <span v-if="scope.row.space_two != 0" style="color: #FF4D4F;display: block;line-height: 0px;transform: scale(-1.2);padding: 3px 0px 0px; margin-top: 5px;font-size: 14px;">⇀</span>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="address"
                :label="$t('robot.trade_currency')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_two }}</span>
                <span v-if="scope.row.space_two != 0" style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space_two }}</span>
                <img v-if="scope.row.robot_type == 'bn'" style="position: absolute; top:0px; right: 1px" src="../../assets/img/robot/binance_sign.svg">
                <img v-if="scope.row.robot_type == 'ok'" style="position: absolute; top:0px; right: 1px" src="../../assets/img/robot/okex_sign.svg">
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="dialogUBList.length != 0" class="rank_div robot-earn-mb" style="margin: 20px -20px; border-top:1px #EAECEF solid"></div>
        <!-- UB交易 -->
        <div v-if="dialogUBList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;min-width: 281px">
          <div style="height: 150px; background: #FFFAEF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.ub_trade') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}:{{ UBNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_ub_new.png">
            </div>
          </div>
          <el-table
              :data="dialogUBList"
              :header-cell-style="{color:'#72777E'}"
              :cell-style="{fontSize: '12px'}"
              style="width: 100%;display: inline-block;vertical-align: top;">
            <el-table-column
                align="center"
                prop="date"
                :label="$t('robot.init_space')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_one }}</span>
                <span style="font-size:12px; padding-top:0px; color: #1E2329;display: block">{{ scope.row.space }}</span>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="name"
                :label="$t('robot.side')">
              <span style="font-size:17px;color: #52C41A;display: block">⇀</span>
            </el-table-column>
            <el-table-column
                align="center"
                prop="address"
                :label="$t('robot.trade_currency')">
              <template slot-scope="scope">
                <span style="font-size:14px;color: #E3B936;display: block">{{ scope.row.coin_two }}</span>
                <img v-if="scope.row.robot_type == 'bn'" style="position: absolute; top:0px; right: 1px" src="../../assets/img/robot/binance_sign.svg">
                <img v-if="scope.row.robot_type == 'ok'" style="position: absolute; top:0px; right: 1px" src="../../assets/img/robot/okex_sign.svg">
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="dialogGridList.length != 0" class="rank_div robot-earn-mb" style="margin: 20px -20px; border-top:1px #EAECEF solid"></div>
        <!-- 網格 -->
        <div v-if="dialogGridList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;min-width: 281px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.grid_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}:{{ GRIDNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_grid_new.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 6px" v-if="dialogGridList.length == 0">
            <span style="padding: 18px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogGridList" v-for="(item, i) in dialogGridList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin:10px">
              <div style="display:inline-block;width: 50%;vertical-align: top">
                <span style="display: block;color: #72777E">{{ $t('robot.deal_pair') }}</span>
                <span style="display: block;color: #AB54DB">{{ item.coin_one }} <span style="font-size:17px;line-height: 6px;color: #52C41A;display: inline-block">⇀</span> {{ item.coin_two }}</span>
<!--                <span style="display: block;color: #1E2329">{{ item.coin }}</span>-->
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.input_amount') }}</span>
                <span style="display: block;color: #1E2329">{{ item.after_space | roundEightFilter }}({{ item.coin_one}})</span>
                <span style="display: block;color: #1E2329">{{ item.front_space | roundEightFilter }}({{item.coin_two}})</span>
              </div>
            </div>
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.Price_range') }}({{ item.coin_one }})</span>
                <span style="display: block;color: #1E2329">{{ item.floor_price | roundEightFilter }}~{{ item.ceiling_price | roundEightFilter }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.number_of_grids') }}</span>
                <span style="display: block;color: #1E2329">{{ item.grid_number }}</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 1px" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 1px" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
        <div v-if="dialogMartinList.length != 0" class="rank_div robot-earn-mb" style="margin: 20px -20px; border-top:1px #EAECEF solid"></div>
        <!-- 馬丁 -->
        <div v-if="dialogMartinList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;min-width: 281px">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.martin_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}:{{ MARTINNum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_martin.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 6px" v-if="dialogMartinList.length == 0">
            <span style="padding: 18px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogMartinList" v-for="(item, i) in dialogMartinList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin:10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.deal_pair') }}</span>
                <span style="display: block;color: #AB54DB">{{ item.coin_one }} <span style="font-size:17px;line-height: 6px;color: #52C41A;display: inline-block">⇀</span> {{ item.coin_two }}</span>
                <!--                <span style="display: block;color: #1E2329">{{ item.coin }}</span>-->
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.policy_type') }}</span>
                <span style="display: block;color: #1E2329">{{ item.strategy_type_name }}</span>
              </div>
            </div>
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.full_order_position') }}(U)</span>
                <span style="display: block;color: #1E2329">{{ item.full_space | roundEightFilter }}</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.stop_ratio') }}</span>
                <span style="display: block;color: #1E2329">{{ item.stop_ratio | roundEightFilter }}%</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 1px" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 1px" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
        <!-- 至尊对冲 -->
        <div v-if="dialogHedgeList.length != 0" class="shouyi_div" style="display: inline-block;vertical-align: top;min-width: 281px;">
          <div style="height: 150px; background: #F3F7FF;">
            <div style="display: inline-block; width: 50%;margin-top: 20px;vertical-align: top;">
              <span class="robot-add-name" style="">{{ $t('robot.grid_contract_strategy') }}</span>
              <span class="robot-add-des" style="">{{ $t('robot.transaction_pair_added') }}：{{ HEDGENum }}</span>
            </div>
            <div style="display: inline-block; width: 50%;text-align: center">
              <img style="width: 128px; height: 140px;" src="../../assets/img/robot/pic_hedge.png">
            </div>
          </div>
          <div class="" style="position: relative;margin: 20px 0px 0px 0px; border-top:1px #EAECEF solid;"></div>
          <div style="text-align: center; margin-top: 2px" v-if="dialogHedgeList==null||dialogHedgeList.length == 0">
            <span style="padding: 21px 0px;color:#909399;display: inline-block; border-bottom: 1px #EBEEF5 solid;width: 100%;font-size: 14px;">{{ $t('robot.no_data') }}</span>
          </div>
          <div v-if="dialogHedgeList" v-for="(item, i) in dialogHedgeList" style="border-bottom: #EBEEF5 1px solid;position: relative">
            <div style="display: block;width: 100%;margin: 10px">
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.yield') }}(U)</span>
                <span style="display: block;color: #1E2329">{{ item.profit_rate ? item.profit_rate : '0.00' | roundEightFilter }}%</span>
              </div>
              <div style="display:inline-block;width: 50%">
                <span style="display: block;color: #72777E">{{ $t('robot.billing_quantity') }}</span>
                <span style="display: block;color: #1E2329">{{ item.trade_count  }}</span>
              </div>
            </div>
            <img v-if="item.robot_type == 'bn'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/binance_sign.svg">
            <img v-if="item.robot_type == 'ok'" style="position: absolute; top:-10px; right: 0" src="../../assets/img/robot/okex_sign.svg">
          </div>
        </div>
      </div>

    </el-dialog>
    <el-image-viewer v-if="imgsVisible" :urlList="[showImgUrl]" :on-close="closeViewer"></el-image-viewer>
  </div>
</template>

<script>
import {
  getMyRankByWeek,
  getTradeCountRankByWeek,
  getUserPosition, getUserPositionNew, getUserProfitByWeekTop10,
  getUserTradeCountByWeekTop10, getUserWeekTradeCount
} from "../../server/robot";
import {Base64} from "js-base64";
import Pop_image from "../common/pop_image";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

export default {
  name: "thisWeekList",
  components: {ElImageViewer},
  data() {
    return {
      title_status: false,
      num: 1,
      token: '',

      userProfitByWeekTop10List: [], //本周开单榜
      myRankNum1: '', //我的排行 開單
      userProfitNum: '0', // 0：全部 1：U币 2：币币 3：黄金三角 4：網格 5：馬丁

      dialogCheckCangwei: false, //看他人倉位設置
      cangweiName: this.$t('robot.everyone_space'),
      dialogHuangjinList: [],
      dialogBBList: [],
      dialogUBList: [],
      dialogGridList:[],
      dialogMartinList: [],
      dialogHedgeList: [],
      huangjinNum: 0,
      BBNum: 0,
      UBNum: 0,
      GRIDNum: 0,
      MARTINNum: 0,
      HEDGENum: 0,
      rankNum: 1,
      weekTradeCount: 0, //本周累计开单量
      testUser: 'false',
      imgsVisible: false,
      showImgUrl: '',
      order_column: 'yield', // 传trade_count开单量  传yield收益率
    }
  },
  created() {
  },
  mounted() {
    this.num = this.$route.query.num;
    this.token = localStorage.getItem('token')
    if(this.num == 1){
      this.getUserTradeCountByWeekTop10();
      this.getTradeCountRankByWeek();
    }else{
      this.getUserProfitByWeekTop10()
      this.getMyRankByWeek();
    }
    if (sessionStorage.getItem('testUser')){
      this.testUser = Base64.decode(sessionStorage.getItem('testUser'))
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.num = to.query.num;
  //   });
  // },
  methods: {
    handleClick(e, nums) {
        this.getUserTradeCountByWeekTop10();
        this.getTradeCountRankByWeek();
    },
    async checkEvery(id, allow_view_position) {
      if (allow_view_position == 1) {
        return
      }
      this.dialogCheckCangwei = true;
      this.dialogHuangjinList = [];
      this.huangjinNum = 0;
      this.dialogBBList = [];
      this.BBNum = 0;
      this.dialogUBList = [];
      this.UBNum = 0;

      const res4 = await getUserPositionNew(id)
      if (res4 && res4.success) {
        if (res4.data) {
          for (let i = 0; i < res4.data.length; i++) {
            if (res4.data[i].product_type == 'sj') {
              this.dialogHuangjinList = res4.data[i].positions
              this.huangjinNum = res4.data[i].positions.length * 3;
            }
            if (res4.data[i].product_type == 'bb') {
              this.dialogBBList = res4.data[i].positions
              this.BBNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'ub') {
              this.dialogUBList = res4.data[i].positions
              this.UBNum = res4.data[i].positions.length;
            }
            if(res4.data[i].product_type == 'grid'){
              this.dialogGridList = res4.data[i].positions
              for (let i=0; i<this.dialogGridList.length;i++){
                this.dialogGridList[i].coin_one = this.dialogGridList[i].coin.split('-')[1]
                this.dialogGridList[i].coin_two = this.dialogGridList[i].coin.split('-')[0]
              }
              this.GRIDNum = res4.data[i].positions.length;
            }
            if(res4.data[i].product_type == 'martin'){
              this.dialogMartinList = res4.data[i].positions
              for (let i=0; i<this.dialogMartinList.length;i++){
                this.dialogMartinList[i].coin_one = this.dialogMartinList[i].coin.split('-')[1]
                this.dialogMartinList[i].coin_two = this.dialogMartinList[i].coin.split('-')[0]
                if (this.dialogMartinList[i].strategy_type == 0){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name0')
                } else if(this.dialogMartinList[i].strategy_type == 1){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name1')
                } else if(this.dialogMartinList[i].strategy_type == 2){
                  this.dialogMartinList[i].strategy_type_name = this.$t('robot.strategy_type_name2')
                }
              }
              this.MARTINNum = res4.data[i].positions.length;
            }
            if (res4.data[i].product_type == 'hedge') {
              this.dialogHedgeList = res4.data[i].positions
              for (let i=0; i<this.dialogHedgeList.length;i++){
                this.dialogHedgeList[i].coin_one = this.dialogHedgeList[i].coin.split('-')[1]
                this.dialogHedgeList[i].coin_two = this.dialogHedgeList[i].coin.split('-')[0]
              }
              this.HEDGENum = res4.data[i].positions.length;
            }
          }
        }
      } else {
        this.$message.error(res4.message)
      }
      if (this.num == 1) {
        await this.getUserWeekTradeCount(id)
      }
    },
    // 獲取本周总开单量
    async getUserWeekTradeCount(uid) {
      const res = await getUserWeekTradeCount(uid)
      if (!res) return
      if(res.success){
        this.weekTradeCount = res.data.trade_count
      }
    },
    // 獲取本周開單榜Top10
    async getUserTradeCountByWeekTop10() {
      this.userProfitByWeekTop10List = []
      const res = await getUserTradeCountByWeekTop10(this.userProfitNum, this.order_column)
      if (res.success) {
        const list = res.data
        this.userProfitByWeekTop10List = list.sort(this.compare('rankNum'))
        this.userProfitByWeekTop10List.forEach(function (item, index) {
          if (item.user_id == '4392') { //4392
            item.vip_type = 3
          }
        })
      } else {
        this.$message.error(res.message)
      }
    },
    compare(p){ //这是比较函数
      return function (m, n) {
        var a = m[p];
        var b = n[p];
        return a - b; //升序
      }
    },
    // 獲取本周開單榜我的排名
    async getTradeCountRankByWeek() {
      const res = await getTradeCountRankByWeek(this.userProfitNum, this.order_column)
      if (res.success) {
        if (res.data == null || res.data == '暫無數據' || res.data.rankNum > 450) {
          this.myRankNum1 = this.$t('robot.not_listed')
        } else {
          this.myRankNum1 = res.data.rankNum
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取本周收益棒TOP10
    async getUserProfitByWeekTop10() {
      const res = await getUserProfitByWeekTop10(this.userProfitNum, 1)
      if (res.success) {
        this.userProfitByWeekTop10List = res.data
        for (let i=0; i<this.userProfitByWeekTop10List.length; i++){
          // this.userProfitByWeekTop10List[i].profit = this.userProfitByWeekTop10List[i].profit.toFixed(2)
          this.userProfitByWeekTop10List[i].trade_profit = (this.userProfitByWeekTop10List[i].trade_profit * 1).toFixed(2)
          if (this.userProfitByWeekTop10List[i].user_id == '4392') { //4392
            this.userProfitByWeekTop10List[i].vip_type = 3
          }
        }
      } else {
        this.$message.error(res.message)
      }
    },
    // 獲取本周收益棒我的排名
    async getMyRankByWeek() {
      const res = await getMyRankByWeek(this.userProfitNum, 1, this.token)
      if (res.success) {
        if (res.data == null || res.data == '暫無數據' || res.data.rankNum > 450) {
          this.myRankNum1 = this.$t('robot.not_listed')
        } else {
          this.myRankNum1 = res.data.rankNum
        }
      } else {
        this.$message.error(res.message)
      }
    },
    goBack() {
      window.history.back()
    },
    showImage(url){
      this.showImgUrl = url ? url : require("../../assets/img/avatar.png")
      this.imgsVisible = true
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__img");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
          // 点击遮罩层时调用关闭按钮的 click 事件
          document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    closeViewer(){
      this.imgsVisible = false
    },
  }
}
</script>

<style scoped>
/*********         机器人收益        *************/
.robot-earn .el-card__body > div:first-child {
  font-size: 16px;
  color: #1E2329;
  font-weight: 600;
}

.robot-earn div:nth-child(2) {
  font-size: 14px;
  color: #72777E;
  margin: 24px 0 10px 0;
}

.robot-earn div:nth-child(3) {
  font-size: 30px;
  color: #000613;
  display: inline-block;
  margin: 0 36px 7px 0;
  vertical-align: top;
}

.robot-earn div:nth-child(4) {
  display: inline-block;
  vertical-align: top;
}

.robot-earn div:nth-child(4) div:first-child {
  font-size: 20px;
  color: #72777E;
  display: inline-block;
}

.robot-earn div:nth-child(4) div:nth-child(2) {
  display: inline-block;
  font-size: 20px;
  line-height: 40px;
  margin: 0 0 0 6px;
  color: #000613;
}

.robot-earn div:nth-child(5) {
  font-size: 12px;
  color: #72777E;
  margin-bottom: 24px;
}

.robot-earn div:nth-child(5) div:nth-child(1) {
  font-size: 12px;
  color: #72777E;
  display: inline-block;
}

.robot-earn div:nth-child(5) div:nth-child(2) {
  display: inline-block;
  margin: 0 0 0 40px;
  font-size: 12px;
  color: #72777E;
}

.robot-earn .trend, .robot-earn .rank {
  display: inline-block;
  width: 49%;
}

.robot-earn .trend .trend-title, .robot-earn .rank .rank-title {
  font-size: 12px;
  color: #72777E;
}

.trend button {
  background-color: red;
  color: white;
  width: 140px;
  font-size: 18px;
  height: 50px;
  border: none;
  border-radius: 4px;
  display: block;
  margin-top: 15px;
}

/************       每日收益           ************/
.everyday-top {
  margin-bottom: 20px;
  position: relative;
}

.everyday-earn .el-card__body .everyday-top > div {
  display: inline-block;
}

.everyday-earn .title {
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}

.everyday-earn .title-sub {
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-left: 16px;
}
>>>.el-table__body{
  width: 100% !important;
}

.el-table__body, .el-table__footer, .el-table__header {
  color: #4e71f7 !important;
}

.everyday-top .tabs {
  position: relative;
  top: 0;
  display: flex !important;
  width: 200px;
  justify-content: space-between;
}

.everyday-top .tabs > div {
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  cursor: pointer;
}

.everyday-top .tabs > div:first-child {
  color: #AB54DB;
}

/*************    收益查询      ***********/
.earn-query .earn-query-top {
  position: relative;
}

.earn-query-top .title {
  font-size: 16px;
  font-weight: 600;
  color: #1E2329;
}

.earn-query-top > .date-picker {
  position: absolute;
  top: -4px;
  right: 0;
}

.robot-msg span {
  font-size: 16px;
}

.robot-add-name {
  display: block;
  color: #1E2329;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-left: 20px;
}

.robot-add-des {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #72777E;
  margin-top: 25px;
  margin-left: 20px;
}

.robot-add-btn {
  display: block;
  color: #AB54DB;
  border-radius: 2px;
  padding: 6px 20px;
  background: none;
  border: 1px solid #AB54DB;
  margin: 40px auto 0;
}

.table tr td {
  text-align: center;
  /*text-align: justify;*/
  /*text-justify: distribute-all-lines;*/
  /*text-align-last: justify;*/
}

.tc {
  padding: 12px 0;
  width: 80px;
  color: #909399;
  font-size: 12px;
}

.th {
  font-size: 14px;
}

.td{
  padding: 5px 0px;
}

.robot-add-btn {
  display: block;
  color: #AB54DB;
  border-radius: 2px;
  padding: 6px 20px;
  background: none;
  border: 1px solid #AB54DB;
  margin: 40px auto 0;
}

.table tr td {
  text-align: center;
  /*text-align: justify;*/
  /*text-justify: distribute-all-lines;*/
  /*text-align-last: justify;*/
}


@media screen and (max-width: 900px) {
  .robot-earn-mb {
    display: block;
  }

  .robot-earn {
    display: none;
  }

  .robot-pc {
    display: none;
  }

  .robot-msg {
    padding: 20px 0;
    border-bottom: #EAECEF 1px solid;
  }

  .trend button {
    background-color: white;
    color: #FF4D4D;
    width: 100%;
    font-size: 18px;
    height: 50px;
    border: #FF4D4D 2px solid;
    border-radius: 4px;
    display: block;
    font-weight: 400;
    margin-top: 15px;
  }

  .everyday-earn .title-sub {
    font-size: 14px;
    font-weight: 400;
    color: #72777E;
    margin-top: 6px;
    margin-left: 0px;
  }

  .shouyi_div {
    width: 100%;
  }

  .dialog-mb {
    margin: -40px auto 0px;
  }

  .dialog-mb-ub {
    margin: 0 auto;
  }

  .sj-svip-id{
    width: 50px;
    margin-top: -1px;
    margin-right: -1px;
  }

  >>>.el-dialog__body{
    padding: 5px 20px 20px 20px !important;
  }
}

/*@media screen and (min-width: 900px) {*/
/*  .robot-earn-mb {*/
/*    display: none;*/
/*  }*/

/*  .robot-earn {*/
/*    display: block;*/
/*  }*/

/*  .shouyi_div {*/
/*    width: 32%;*/

/*  }*/

/*  .shouyi_div2 {*/
/*    margin: 0 2%;*/
/*  }*/

/*  .robot-pc {*/
/*    display: block;*/
/*  }*/

/*  .dialog-mb {*/
/*    margin: -95px auto 0px;*/
/*  }*/

/*  .dialog-mb-ub {*/
/*    margin: 0 auto;*/
/*  }*/
/*  .check_cw {*/
/*    color: #AB54DB;*/
/*    font-size: 14px;*/
/*    width: max-content;*/
/*    cursor: pointer*/
/*  }*/
/*  .uncheck_cw {*/
/*    color: #72777E;*/
/*    font-size: 14px;*/
/*    width: max-content;*/
/*    cursor: pointer*/
/*  }*/
/*}*/

>>> .el-tabs__item {
  padding: 0px 8px;
}
>>>.el-tabs__nav-wrap::after{
  height: 1px;
}
>>>.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}

>>>.el-tabs__item.is-active {
  color: #AB54DB;
}

>>>.el-tabs__active-bar{
  background-color: #AB54DB;
}
>>>.el-table .cell {
  line-height: unset;
}
>>>.el-dialog .el-dialog__body {
  flex:1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.rank_num_color_y {
  color: #E3B936;
  font-size: 14px;
  font-weight: 600;
}

.rank_num_color_ {
  color: #000613;
  font-size: 14px;
}

>>>.el-tabs__nav-scroll{
  overflow-x: auto;
}

>>>.el-tabs__nav-wrap{
  padding: 15px;
}

>>>.el-tabs__nav-prev{
  /*display: none;*/
  color: #1E2329;
}

>>>.el-tabs__nav-next{
  /*display: none;*/
  color: #1E2329;
}

>>>.el-tabs__nav-wrap::after {
  height: 0px;
}

</style>
